import React from "react";

import { NavLink } from "react-router-dom";

export default class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.openAccordion = this.openAccordion.bind(this);
  }

  componentWillMount() {}

  openAccordion(link, ele) {
    if (link === "unity" || link === "gallery") {
      this.props.history.push(link);
    } else if (ele.target.parentNode.className.indexOf("open") !== -1) {
      ele.target.parentNode.className = "";
    } else {
      document.querySelectorAll(".Nav ul")[0].className = "";
      ele.target.parentNode.className += " open";
    }
  }

  render() {
    return (
      <div
        className={this.props.navType + " Nav"}
        style={{ position: "fixed", left: 0, top: 0 }}
      >
        <ul>
          <NavLink to="/">
            <li>Home</li>
          </NavLink>
        </ul>
        <ul>
          <li onClick={this.openAccordion.bind(this, false)}>Secure Sources</li>
          <NavLink to="/secure-sources">
            <li>the last repo you'll need</li>
          </NavLink>
          <NavLink to="/ss-lite">
            <li>the second last repo you'll need</li>
          </NavLink>
        </ul>
        <div className="Nav__colorBar"></div>
        <ul>
          {/* <li onClick={this.openAccordion.bind(this, false)}>Past Work</li>
          <li>
            <a href="https://cfl.ca" target="_blank" rel="noopener noreferrer">
              CFL
            </a>
          </li> */}
          <NavLink to="/ubisoft">
            <li>ubisoft</li>
          </NavLink>
          <NavLink to="/carelnk">
            <li>gsh medical</li>
          </NavLink>
          <li>
            <a href="http://tiff.net" target="_blank" rel="noopener noreferrer">
              tiff
            </a>
          </li>
        </ul>
        <div className="Nav__colorBar"></div>
        <ul>
          <li onClick={this.openAccordion.bind(this, false)}>Random</li>
          <NavLink to="/special-request">
            <li>special re: quest</li>
          </NavLink>
          <NavLink to="tokyo">
            <li>tokyo</li>
          </NavLink>
        </ul>
      </div>
    );
  }
}
