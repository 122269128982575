import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import App from "../App/App";

const ThreeScene = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer();

    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Create cube
    // const geometry = new THREE.BoxGeometry(1, 1, 1); // x,y,x
    // const material = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
    // const cube = new THREE.Mesh(geometry, material);
    // cube.position.y = 0.5; // Position the cube 0.5 units above the plane
    // scene.add(cube);

    // Create plane
    // Create a larger plane geometry for the floor
    const floorGeometry = new THREE.PlaneGeometry(200, 200, 200, 200);

    // Create a shader material for the checkered pattern
    const floorMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uSize: { value: 40.0 },
        uColorA: { value: new THREE.Color(0xff819c) }, // Pink
        uColorB: { value: new THREE.Color(0x000000) }, // Black
      },
      vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
      fragmentShader: `
    uniform float uSize;
    uniform vec3 uColorA;
    uniform vec3 uColorB;
    varying vec2 vUv;
    void main() {
      vec2 pos = floor(vUv * uSize);
      float pattern = mod(pos.x + pos.y, 2.0);
      vec3 color = mix(uColorB, uColorA, pattern);
      gl_FragColor = vec4(color, 1.0);
    }
  `,
    });

    // Create the floor mesh
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);

    // Rotate the floor to be horizontal
    floor.rotation.x = -Math.PI / 2;

    // Position the floor slightly below the origin
    floor.position.y = -0.01;

    // Add the floor to your scene
    scene.add(floor);

    // Adjust the camera
    camera.position.set(0, 5, 15);
    camera.position.set(
      58.04499893393169,
      34.30782715944001,
      -21.49275962340113
    );
    // camera.lookAt(0, 0, 0);

    const loader = new THREE.CubeTextureLoader();

    const skyboxTexture = loader.load([
      "arid2_rt.jpg",
      "arid2_lf.jpg",
      "arid2_up.jpg",
      "arid2_dn.jpg",
      "arid2_ft.jpg",
      "arid2_bk.jpg",
    ]);

    // Set the scene background to the loaded cube texture
    scene.background = skyboxTexture;

    // If you want to add the skybox as a mesh (optional):
    const skyboxGeometry = new THREE.BoxGeometry(10000, 10000, 10000);
    const skyboxMaterial = new THREE.MeshBasicMaterial({
      envMap: skyboxTexture,
      side: THREE.BackSide,
    });
    const skybox = new THREE.Mesh(skyboxGeometry, skyboxMaterial);
    scene.add(skybox);

    // Add lights
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    // camera.position.z = 5;

    // Add OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    controls.enablePan = true;

    // Set pan speed (optional)
    // controls.panSpeed = 1.0;

    // camera.position.set(0, 2, 20); // Set initial camera position

    // Video texture setup
    // const video = document.createElement("video");
    // video.src = "/home.mp4";
    // video.crossOrigin = "anonymous"; // Optional if video is from a different origin
    // video.loop = true;
    // video.muted = true;
    // video.play();

    // const videoTexture = new THREE.VideoTexture(video);
    // videoTexture.minFilter = THREE.LinearFilter;
    // const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
    // const videoGeometry = new THREE.PlaneGeometry(16, 9); // Adjust size as needed
    // const videoMesh = new THREE.Mesh(videoGeometry, videoMaterial);
    // const videoMesh1 = new THREE.Mesh(videoGeometry, videoMaterial);
    // videoMesh.position.set(0, 5, 0); // Example position
    // scene.add(videoMesh);
    // videoMesh1.position.set(0, 5, 0); // Example position
    // videoMesh1.rotation.y = Math.PI;
    // scene.add(videoMesh1);

    // Add keyboard controls
    const moveSpeed = 0.1;
    const keyState = {};

    window.addEventListener("keydown", (e) => {
      keyState[e.code] = true;
    });

    window.addEventListener("keyup", (e) => {
      keyState[e.code] = false;
    });

    const updateCamera = () => {
      console.log(camera.position.x, camera.position.y, camera.position.z);
      if (keyState["KeyW"]) camera.position.z -= moveSpeed;
      if (keyState["KeyS"]) camera.position.z += moveSpeed;
      if (keyState["KeyA"]) camera.position.x -= moveSpeed;
      if (keyState["KeyD"]) camera.position.x += moveSpeed;
      if (keyState["Space"]) camera.position.y += moveSpeed;
      if (keyState["ShiftLeft"]) camera.position.y -= moveSpeed;
    };

    const curve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(-10, 0, 10),
      new THREE.Vector3(-5, 5, 5),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(5, -5, 5),
      new THREE.Vector3(10, 0, 10),
    ]);

    let progress = 0;
    const loopTime = 10000; // Time to complete one loop (in milliseconds)

    const animate = () => {
      requestAnimationFrame(animate);
      // cube.rotation.x += 0.01;
      // cube.rotation.y += 0.01;
      // camera.position.z += moveSpeed;

      // const time = Date.now() % loopTime;
      // progress = time / loopTime;

      // // Get the point on the curve
      // const point = curve.getPointAt(progress);

      // // Update camera position
      // camera.position.copy(point);

      updateCamera();
      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      <>
        window.removeEventListener("resize", handleResize);
        mountRef.current.removeChild(renderer.domElement);
      </>;
    };
  }, []);

  return <div ref={mountRef} />;
};

function OverlayUI() {
  return (
    <div style={{ position: "absolute", top: 10, left: 10, color: "white" }}>
      <h2>React Overlay</h2>
      <button>Click me!</button>
    </div>
  );
}
function NewApp() {
  return (
    <div>
      {ReactDOM.createPortal(<App />, document.body)}
      {/* <ThreeScene /> */}
    </div>
  );
}

export default NewApp;
